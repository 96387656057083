import React, { useState } from 'react'
import { Accordion, Badge, Image } from 'react-bootstrap'
import { get, sumBy } from 'lodash'
import { Col, Typography } from 'antd'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ReturnedItem from './ReturnedItem'
import icons from 'webapp/images/icons'

const { Text, Title } = Typography

const TransactionItem = ({ country, eventKey, transaction }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const { t } = useTranslation()
  const date = new Date(transaction.created_at)
  const formattedDate = date.toLocaleDateString(country, { day: 'numeric', month: 'short' })

  const isScan = transaction.category === 'SCAN'
  const isCompletedScan = isScan && transaction.status === 'COMPLETED'
  const isPendingScan = isScan && transaction.status === 'PENDING'
  const isRejectedScan = isScan && transaction.status === 'REJECTED'
  const isPending = transaction.status === 'PENDING'
  const totalReturnQuantity = (isCompletedScan && transaction?.items) ? sumBy(transaction.items, 'quantity') : 0

  const getDescription = () => {
    switch (transaction.category) {
    case 'SCAN':
      return isPendingScan ? <PendingScanContent translation={t} /> : null
    case 'ADJUSTMENT':
      return <Text>{t('adjustmentDescription')}</Text>
    case 'WITHDRAWAL':
      return <WithdrawalContent transaction={transaction} translation={t} />
    default:
      return
    }
  }

  const titleMap = {
    ADJUSTMENT: {
      default: t('adjustment'),
    },
    SCAN: {
      CANCELLED: t('cancelledItem', { count: transaction.number_of_scans }),
      PENDING: t('pendingItem', { count: transaction.number_of_scans }),
      REJECTED: t('rejectedItem', { count: transaction.number_of_scans }),
      true: t('returnItem', { count: totalReturnQuantity }),
    },
    WITHDRAWAL: {
      CANCELLED: t('cancelledWithdrawal'),
      default: t('withdrawal'),
      FAILED: t('cancelledWithdrawal'),
      PENDING: t('pendingWithdrawal'),
    },
  }

  const getTitle = ({ category, status } = transaction) => (
    category === 'SCAN'
      ? get(titleMap, [category, isCompletedScan || status])
      : get(titleMap, [category, status], get(titleMap, [category, 'default']))
  )

  const getColor = () => (
    (transaction.status === 'CANCELLED' || transaction.status === 'FAILED' || isRejectedScan)
      ? 'black'
      : isPending ? 'orange' : ''
  )

  return (
    <Accordion activeKey={isAccordionOpen ? eventKey : null} className="mb-3 transactionItem" onSelect={() => setIsAccordionOpen(!isAccordionOpen)}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>
          <div className={`d-flex justify-content-between transactionTitle ${getColor()}`}>
            <Title level={5}>
              {getTitle()}
            </Title>

            {!(isPendingScan || isRejectedScan) && (
              <Title level={5}>
                {new Intl.NumberFormat(country, { currency: transaction.amount.currency, style: 'currency' }).format(transaction.amount.value)}
              </Title>
            )}
          </div>

          <p>{formattedDate}</p>

          {(isCompletedScan || isRejectedScan) && !isAccordionOpen && (
            <Col className={`d-flex flex-row w-100 mt-2 product-images-wrapper itemWrapper ${isAccordionOpen ? '' : 'visible'}`}>
              {React.Children.toArray(transaction?.items?.map((item) =>
                <div className="position-relative">
                  <LazyLoad height={200} offset={100} once>
                    <Image
                      alt="bottle"
                      className={`${loaded ? 'loaded' : ''}`}
                      onLoad={() => setLoaded(true)}
                      src={item.image_url || icons.Bottle}
                    />
                  </LazyLoad>
                  {item.quantity > 1 &&
                    <Badge style={{
                      bottom: '0',
                      position: 'absolute',
                      right: '0',
                    }}
                    variant="light"
                    >
                      {item.quantity}
                    </Badge>
                  }
                </div>
              ))}
            </Col>
          )}
        </Accordion.Header>
        <Accordion.Body className="d-flex flex-column pt-0">
          {transaction.comment && (
            <Text>{transaction.comment}</Text>
          )}

          {getDescription()}

          {(isCompletedScan || isRejectedScan) && (
            React.Children.toArray(transaction?.items?.map((item) =>
              <ReturnedItem country={country} isRejected={isRejectedScan} item={item} />
            ))
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

const PendingScanContent = ({ translation }) => (
  <>
    <Title level={5}>{translation('pendingTextTitle')}</Title>
    <Text>{translation('pendingText')}</Text>
  </>
)

PendingScanContent.propTypes = {
  translation: PropTypes.func,
}

const WithdrawalContent = ({ translation, transaction }) => (
  <>
    {transaction.account_name && <p><Text strong>{translation('name')}: </Text></p>}
    <Text>{transaction.account_name}</Text>
    <p className="mb-0"><Text strong>{translation('accountNumber')}</Text></p>
    <Text>{transaction.account_number}</Text>
  </>
)

WithdrawalContent.propTypes = {
  transaction: PropTypes.shape({
    account_name: PropTypes.string,
    account_number: PropTypes.string,
  }).isRequired,
  translation: PropTypes.func,
}

TransactionItem.propTypes = {
  country: PropTypes.string.isRequired,
  eventKey: PropTypes.string.isRequired,
  transaction: PropTypes.shape({
    account_name: PropTypes.string,
    account_number: PropTypes.string,
    amount: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
    category: PropTypes.string.isRequired,
    comment: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    items: PropTypes.array,
    number_of_scans: PropTypes.number,
    price: PropTypes.string,
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default TransactionItem
