import React from 'react'
import { Trans } from 'react-i18next'
import { countries } from 'webapp/constant'
import { CountryChange } from 'webapp/components'
import mapImages from 'webapp/images/maps'

const CountrySelector = () => {
  const selectCountry = (country, locale) => {
    localStorage.setItem('country', locale.split('-')[1].toLowerCase())
    localStorage.setItem('locale', locale)

    window.location.reload(false)
  }

  return (
    <div className="container mx-auto map-wrapper">
      <h1 className="mt-3">
        <Trans>selectCountry</Trans>
      </h1>

      <div
        className="map-container country-selector-container"
        style={{
          backgroundImage: `url(${mapImages.NoSelected})`,
        }}
      >
        <svg
          height="621.681"
          viewBox="0 0 1360.862 621.681"
          width="1360.862"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            data-name="World Map"
            id="World_Map"
            transform="translate(1.066 0.5)"
          >
            {React.Children.toArray(countries.map((country, index) =>
              <g
                className="image-group"
                data-name={`Group${index}`}
                id={`Group_${index}`}
                onClick={() => { selectCountry(country.name, country.locale) }}
                transform={country.gtransform}
              >
                <path
                  className="contour-path"
                  d={`${country.d}`}
                  data-name={`Path ${index}`}
                  stroke="#f1f1f1"
                  strokeWidth="1"
                  transform={country.transform}
                />

                <svg
                  height="40px"
                  style={{ overflow: 'visible' }}
                  version="1.1"
                  width="40px"
                  x={country.pin.x}
                  xmlns="http://www.w3.org/2000/svg"
                  y={country.pin.y}
                >
                  <image
                    className="country-pin"
                    height="40px"
                    href={country.pin.image}
                    src={country.pin.image}
                    width="40px"
                  />
                </svg>
              </g>
            ))}
          </g>
        </svg>
      </div>
      <CountryChange reload={true} />
    </div>
  )
}

export default CountrySelector
