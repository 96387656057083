import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { CookiesProvider } from 'react-cookie'
import CountrySelector from './CountrySelector'
import { LanguageProvider } from './modules'
import App from './App'
import './modules/i18n'
import logo from './images/loop-logo.svg'

import './index.scss'

const isProd = process.env.RAILS_ENV === 'production'
const oneTrustKey = isProd ? '018ec2af-ea72-78d7-b936-741f39cf37a4' : '018ec2af-ea72-78d7-b936-741f39cf37a4-test'

const Root = () => (
  <BrowserRouter>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>

      <Helmet>
        <script
          src={`https://cdn.cookielaw.org/consent/${oneTrustKey}/OtAutoBlock.js`}
          type="text/javascript"
        >
        </script>

        <script
          charSet="UTF-8"
          data-domain-script={oneTrustKey}
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
        >
        </script>
      </Helmet>

      <script
        charSet="UTF-8"
        data-domain-script={oneTrustKey}
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
      >
      </script>

      <title>Loop deposit web</title>
      <meta content="Manage your balance and request withdrawals" name="description" />
      <meta content="loop, recycling, reusable, sustainable" name="keywords" />
      <meta content="Terra Cycle - Loop" name="author" />
      <meta content="Loop deposit web" property="og:title" />
      <meta content="Manage your balance and request withdrawals" property="og:description" />
      <meta content={logo} property="og:image" />
      <meta content="Loop deposit web" name="twitter:title" />
      <meta content="Manage your balance and request withdrawals" name="twitter:description" />
      <meta content={logo} name="twitter:image" />

      <LanguageProvider>
        {localStorage.getItem('country')
          ? <App />
          : <CountrySelector />
        }
      </LanguageProvider>
    </CookiesProvider>
  </BrowserRouter>
)

export default Root
